import './App.css';
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import BlogPost from "./pages/BlogPost";
import NavBar from './components/NavBar';
import About from './pages/About';
import Contact from './pages/Contact'
import { React, useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Set the theme for the whole web app 
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const [getPostContent, setPostContent] = useState([]);

  const getData = (blog) => {
    setPostContent(blog);
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div>
        <NavBar></NavBar>
        <div className="container">
          <Routes>
            <Route path="/" element={<HomePage data={getData} />} />
            <Route path="/post/:id" element={<BlogPost content={getPostContent} setContent={setPostContent} />} />
            <Route path="/About" element={<About />} />
            <Route path="/Contact" element={<Contact />} />
          </Routes>
        </div>
      </div>
    </ThemeProvider>

  );
}

export default App;
