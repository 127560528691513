import React from "react";
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// Define styles using makeStyles
const aboutPageStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
};

const About = () => {
    return (
        <Container className={aboutPageStyles} maxWidth="md">
            {/* Content for the About Page */}
            <Typography variant="h1" gutterBottom>
                magazine95
            </Typography>

            <Typography variant="h5" gutterBottom>
                magazine95.com intricately weaves together the realms of creativity,
                authenticity, and passion, encapsulating a dynamic blend of art, culture,
                fashion, and growth.
            </Typography>
            <br />
            <Typography variant="h5" gutterBottom>
                <strong>Unleash your Creativity.</strong> Venture forth and bring to life that which is uniquely yours.
            </Typography>
            <Typography variant="h5" gutterBottom>
                <strong>Embrace Honesty.</strong> Be genuine about your identity and the creations you bring to life.
            </Typography>
            <Typography variant="h5" gutterBottom>
                <strong>Cultivate Passion.</strong> Infuse passion into everything you do; let it be the driving force behind your actions.
            </Typography>
            <br />
            <Typography variant="h5" gutterBottom>
                <Box sx={{ fontStyle: 'oblique', m: 1 }}>
                    The magazine is based in Philadelphia. The ideas are global.
                </Box>
            </Typography>
            {/* Add more content as needed */}

        </Container>
    );
};

export default About;