import { React, useState, useCallback } from 'react';
import '../index.css';
import Footer from '../components/Footer';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Markdown from '../components/Markdown';
import { useLocation, useParams } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { getPost } from '../graphql/queries';


const blogPostStyles = {
  root: {
    // display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  card: {
    maxWidth: 2000,
  },
  media: {
    height: 400,
  },
};


const Blog = ({ content, setContent }) => {
  const { id } = useParams()
  const [markdown, setMarkdown] = useState("");
  const location = useLocation();

  const fetchPost = useCallback(async () => {
    const client = generateClient();
    const apiData = await client.graphql({
      query: getPost,
      variables: { id: id }
    });
    const data = apiData.data.getPost;
    console.log(data)
    content = data
    setContent(data)
    sessionStorage.setItem(location.pathname, JSON.stringify(data))
  }, [id])

  // lets check if there is anything in the sessionStorage for this path 
  const storedState = sessionStorage.getItem(location.pathname)

  // TODO There is a weird race condition to explore here...
  // First we need to check if content is set from param
  if (!content && Object.keys(content).length !== 0) {
    // we are good, just used what was passed in 
  } else if (storedState) {
    // lets check the session memory for stuff
    content = JSON.parse(storedState)
  } else {
    // all else fails, just make an api call 
    fetchPost()
      .catch(error => {
        console.log(error)
      });
  }

  // there is content
  fetch(content.content)
    .then(response => response.text())
    .then(markdown => setMarkdown(markdown));
  // Set the info here 

  sessionStorage.setItem(location.pathname, JSON.stringify(content))
 
  return (
    <Container style={blogPostStyles.root}>
      <Card style={blogPostStyles.card}>
        <CardMedia
          style={blogPostStyles.media}
          component="img"
          alt="Blog Post Image"
          height="140"
          image={content.image} // Replace with your actual image URL
        />
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            {content.title}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {content.summary}
          </Typography>
        </CardContent>
      </Card>
      <br />

      {Object.keys(content).length === 0 ?
        <Markdown className="markdown" key={''}>
          {markdown}
        </Markdown> :
        <Markdown className="markdown" key={content.content.substring(0, 40)}>
          {markdown}
        </Markdown>
      }
      <Footer
        title="magazine95"
        description="Create culture, art and ideas"
      />
    </Container>
  );
};
export default Blog;