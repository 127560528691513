import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import TempDrawer from './TemporaryDrawer';

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

const NavItem = styled.li`
  list-style: none;
  margin: 0 1rem;

  a {
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      color: #ffffff14;
      text-decoration: underline;
    }
  }
`;

const Logo = styled.h1`
  font-size: 1.5rem;
  margin: 0;
  a {
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      color: #ffffff14;
    }
  }
`;

const MobileNavToggle = styled.button`
  display: block;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  @media (min-width: 768px) {
    display: none;
  }
`;

const DesktopNav = styled.ul`
  display: flex;
  flex-direction: row;

  @media (max-width: 767px) {
    display: none;
  }
`;

const NavBar = () => {
  return (
    <Nav>
      <Logo>
        <Link to="/"> magazine95 </Link>
      </Logo>
      {/* if mobileNav is toggled, call tempDrawer component which renders the menu button. */}
      <MobileNavToggle>
        <TempDrawer/>
      </MobileNavToggle>
      <DesktopNav>
        <NavItem><Link to="/">ARTICLES</Link></NavItem>
        <NavItem><Link to="/About">ABOUT</Link></NavItem>
        <NavItem><Link to="/Contact">CONTACT</Link></NavItem>
      </DesktopNav>
    </Nav>
  );
};

export default NavBar;