import React from 'react';
import BlogItem from './BlogItem';
import '../index.css';
const BlogList = ({ posts, content }) => {
  return (
    <div className='blogList-wrap'>
      {posts.map((post) => (
        <BlogItem post={post} content={content} key={post.id} />
      ))}
    </div>
  );
};
export default BlogList;