import React from 'react';
import Chip from '@mui/material/Chip';
import '../index.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function formateDate(string) {
  var options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(string).toLocaleDateString([], options);
}

const BlogItem = ({ post, content }) => {
  return (
    <Card sx={{ maxWidth: 600 }}>
      <CardActionArea component={RouterLink}
        to={`/post/${post.id}`}
        onClick={() => { content(post) }}
      >
        <CardMedia
          sx={{ height: 250, objectFit: "contain" }}
          image={post.image}
          alt={post.title}
          title={post.title}
        />
        <CardContent>
          <Typography variant="h5" component="div">
            {post.title}
          </Typography>
          <Divider textAlign='right'>
            <Chip label={post.category} size="small" />
          </Divider>
          <Typography variant='subtitle1'>
            {formateDate(post.date)}
          </Typography>
          <Typography variant='subtitle1'>
            by {post.author}
          </Typography>
          <br />
          <Typography variant="body2" color="text.secondary">
            {post.summary}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
export default BlogItem;