import { React, useState, useEffect } from 'react';
import EmptyList from '../components/EmptyList';
import BlogList from '../components/BlogList';
import { listPosts } from '../graphql/queries'
import { generateClient } from 'aws-amplify/api';

const HomePage = ({ data }) => {
  const client = generateClient();

  //Used for querying if wanted by types 
  const [posts, setPosts] = useState([]);

  async function fetchPosts() {
    const apiData = await client.graphql({ query: listPosts });
    const postFromAPI = apiData.data.listPosts.items;
    setPosts(postFromAPI)
  }

  useEffect(() => {
    fetchPosts();
  }, []);

  // function to get selected blog content
  // the id is the json for the given blog post
  // TODO make more descriptive names
  const BlogContent = (id) => {
    data(id);
  }

  return (
    <div>
      {/* Blog List & Empty View */}
      {!posts.length ? <EmptyList /> : <BlogList posts={posts} content={BlogContent} />}
    </div>
  );
};
export default HomePage;