import * as React from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { SwipeableDrawer, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const Logo = styled.p`
  color: #fff;
  position: absolute;
  font-size: 1.2rem;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 40px;
  text-align: center;
`;
const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.0rem;
  cursor: pointer;
  transition: all 0.3s ease;
`;
//styling of the link using styled-components.
const StyledLink = styled(Link)`
  background-color: transparent;
  color: #fff;
  font-size: 1.0rem;
  text-decoration: none;
`;
/*
TempDrawer is a basically the Drawer function from MUI. Uses Reacts usestate as well as a const function that takes in an event and sets the 
state of the anchor. List is opened if anchor is true, then TempDrawer returns the actual meat and bones of the function.
*/
const links = ['/', '/About', '/Contact']
const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <StyledLink color="inherit" href="/">
        magazine95
      </StyledLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function TempDrawer() {
  const [state, setState] = React.useState({
    right: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['HOME', 'ABOUT', 'CONTACT'].map((text, index) => (
          <StyledLink to={links[index]}>
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          </StyledLink>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <MenuButton onClick={toggleDrawer(anchor, true)}>MENU</MenuButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
          >
            {list(anchor)}
            <Logo>
              magazine95
              <Typography
                variant="subtitle1"
                align="center"
                color="text.secondary"
                component="p"
              >
                Create culture, art and ideas
              </Typography>
              <Copyright/>
            </Logo>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}